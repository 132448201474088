.floating-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #0394B5; /* Adjust color as needed */
    border: none;
    border-radius: 50%;
    width: 60px; /* Adjust size as needed */
    height: 60px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .floating-button:hover {
    background-color: #2CB4D6; /* Adjust color on hover */
  }
  