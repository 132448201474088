.chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .chatbot-card {
    position: relative;
    width: 300px;
    height: 400px;
    background: rgba(255, 255, 255, 0.9); /* Glass effect */
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .chatbot-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8); /* Slightly opaque background */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .chatbot-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .chatbot-intro {
    flex: 1;
  }
  
  .chatbot-close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .chatbot-body {
    padding: 10px;
    height: calc(100% - 60px); /* Adjust height to fit in the card */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .chat-message {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .chat-message.user {
    background-color: #e1ffc7; /* User message color */
    align-self: flex-end;
  }
  
  .chat-message.bot {
    background-color: #f1f1f1; /* Bot message color */
    align-self: flex-start;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.8);
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chat-input button {
    background-color: #0394B5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .chatbot-open-button {
    background-color: #0394B5;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 15px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
  
  .chatbot-container.open .chatbot-open-button {
    display: none;
  }
  
  .chatbot-container.closed .chatbot-card {
    display: none;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  