/* WaitlistForm.css */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .animate-spin {
    animation: spin 1s linear infinite;
  }
  /* Add these styles in your global CSS or as inline styles */
.bg-opacity-50 {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .transform {
    transform: scale(0.95);
  }
  